import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import { Images } from "../../../utils";

export default function AffiliateContent() {
  return (
    <div className="affiliate-content-wrap">
      <div className="header-view">
        <Fade bottom>
          <div className="hello">
            <img className="msg" alt="" src={Images.Homepage.affiliate3} />
            <img alt="" className="logo" src={Images.Homepage.affiliate5} />
          </div>
        </Fade>
        <div className="tille-wrap">
          <Fade bottom>
            <span>
              Lanatomy AI<span className="line-through">Affiliate</span> Program
            </span>
          </Fade>
          <Fade bottom>
            <img alt="" src={Images.Homepage.affiliate2} />
          </Fade>
        </div>
      </div>
      <div className="main">
        <Zoom>
          <img alt="" src={Images.Homepage.affiliate1} />
        </Zoom>
        <Fade bottom>
          <div className="left-detail">
            <div className="title-row">
              <span className="title">Win-Win</span>
              <img alt="" src={Images.Homepage.affiliate6} />
            </div>
            <span className="detail">
              Vừa giúp chính mình và mọi người giải quyết nỗi đau từ vựng khi
              học ngoại ngữ
            </span>
            <span className="detail2">
              Vừa tạo cho bản thân nguồn thu nhập hấp dẫn cho bản thân từ việc
              giới thiệu app đến đúng đối tượng
            </span>
          </div>
        </Fade>
        <Bounce delay={500}>
          <a href="mailto:info@lanatomy.ai?subject='Lanatomy AI Affiliate Program'">
            <div className="btn">
              <span>Liên hệ ngay</span>
              <img alt="" src={Images.Homepage.affiliate7} />
            </div>
          </a>
        </Bounce>
        <Fade bottom>
          <div className="stamp">
            <img alt="" src={Images.Homepage.affiliate4} />
          </div>
        </Fade>
      </div>
    </div>
  );
}
