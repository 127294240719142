import Fade from "react-reveal/Fade";
import { Images } from "../../../utils";
import { useNavigate } from "react-router-dom";

export default function Navigation({ isHome }) {
  const navigate = useNavigate();
  // const ref = useRef();
  // const [leftMenu, setLeftMenu] = useState(-200);

  // useOnClickOutside(ref, () => setLeftMenu(-200));

  // const scrollTo = (id) => {
  //   document.getElementById(id)?.scrollIntoView({
  //     behavior: "smooth",
  //   });
  //   // setLeftMenu(-200);
  // };

  // const onShowMenu = () => {
  //   setLeftMenu(0);
  // };

  return (
    <>
      <Fade bottom>
        <div className="nav-wrap">
          <div className="logo-wrap">
            <img
              alt=""
              className="pointer logo"
              src={Images.Homepage.MobileIcon}
            />
            <b>LANATOMY AI</b>
          </div>
          <div className="nav-group">
            {isHome && (
              <span
                className="pointer"
                onClick={() => {
                  window.open("/deleteAccount");
                }}
              >
                Delete Account
              </span>
            )}
            <span
              className="pointer"
              onClick={() => {
                window.open("https://discord.gg/lanadojovn");
              }}
            >
              Cộng Đồng LanaDojo VN
            </span>
            <span
              className="pointer"
              onClick={() => {
                window.open("https://lanadojo.com");
              }}
            >
              Bản Web LanaDojo
            </span>
            <span
              className="pointer"
              onClick={() => {
                window.open(
                  "https://hanaslexis.com/workshop-ti%E1%BA%BFng-anh"
                );
              }}
            >
              Workshop Tiếng Anh
            </span>
          </div>
        </div>
      </Fade>

      {/* <div className="nav-wrap-mobile">
        <div className="logo-wrap">
          <img
            alt=""
            className="pointer logo"
            src={Images.Homepage.MobileIcon}
          />
          <img alt="" className="pointer" src={Images.Homepage.AppName} />
        </div>
      </div> */}
    </>
  );
}
