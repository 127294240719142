import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsOfService from "../TermsOfService";
import Affiliate from "./affiliate";
import DeleteAccount from "./deleteAccount";
import ErrorPage from "./ErrorPage";
import Main from "./main";

const router = createBrowserRouter([
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/affiliate",
    element: <Affiliate />,
  },
  {
    path: "/deleteAccount",
    element: <DeleteAccount />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
