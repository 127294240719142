import Footer from "../Footer";
import Homepage from "../homepage";
import AffiliateBenefit from "../Affiliate/Benefit";
import StatBenefit from "../Affiliate/Stats";
import AffiReason from "../Affiliate/Reason";

export default function Affiliate() {
  return (
    <div className="root-app" id="root-app">
      <Homepage isHome={false} />
      <AffiliateBenefit />
      <StatBenefit />
      <AffiReason />
      <Footer />
    </div>
  );
}
