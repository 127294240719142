import "./index.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Images } from "../../utils";
import Fade from "react-reveal/Fade";

export default function Story() {
  return (
    <div className="story">
      <div className="title1">
        <Fade bottom delay={300}>
          <span>Nạp từ vựng theo tin tức nhờ</span>
        </Fade>
        <Fade bottom delay={300}>
          <span>
            <span className="line-through">truyện chêm</span> tiếng Anh
          </span>
        </Fade>
      </div>

      <div className="story-content">
        <div className="img-main">
          <Fade bottom>
            <img alt="" src={Images.story.storyDemo} />
          </Fade>
          <Fade bottom>
            <span>
              Đắm chìm vào thế giới từ vựng cùng Lanatomy AI. Từ truyện chêm tự
              soạn, truyện cổ văn chương, đến các YouTube video thuộc chủ đề tùy
              thích.
            </span>
          </Fade>
          <Fade bottom>
            <div className="bookmark">
              <img alt="" className="story6" src={Images.story.story6} />
              <div className="notice">
                <img alt="" className="story5" src={Images.story.story5} />
                <span>Lưu thẻ từ</span>
              </div>
            </div>
          </Fade>
        </div>
        <Fade bottom delay={300}>
          <img alt="" className="story-1" src={Images.story.story1} />
        </Fade>
        <div className="story-2">
          <Fade bottom delay={1000}>
            <span className="note-wrap">
              Nhớ nhanh từ vựng mới theo quy tắc{" "}
              <span className="line-through">80-20</span> nhờ hiểu ngữ cảnh bài
              viết <span className="line-through">tiếng Việt</span>
            </span>
            <img alt="" className="line" src={Images.story.line} />
          </Fade>
          <Fade bottom delay={500}>
            <img alt="" className="story" src={Images.story.story2} />
          </Fade>
        </div>
      </div>
    </div>
  );
}
