import "./index.scss";
import Images from "../../../utils/images";
import Flip from "react-reveal/Flip";

export default function StatBenefit() {
  return (
    <div className="StatBenefit">
      <Flip bottom>
        <div className="stats1">
          <img className="circle1" alt="" src={Images.affiStats.affiStats8} />
          <img className="circle2" alt="" src={Images.affiStats.affiStats8} />
          <img className="circle3" alt="" src={Images.affiStats.affiStats8} />
          <div className="extra">
            <img alt="" src={Images.affiStats.affiStats1} />
            <span>Thu nhập hấp dẫn</span>
          </div>
          <span className="title">
            <span className="line-through">Cao hơn hẳn</span> thị trường thương
            mại điện tử
          </span>
          <span className="detail">
            ví dụ, khi giới thiệu được <span className="line-through">một</span>{" "}
            người mua app theo gói năm, affiliate có thể được nhận hoa hồng
            tương ứng số tiền 500k - 700k
          </span>
          <div className="graph">
            <div className="cols">
              <div className="col">
                <span className="title1">~1.2%</span>
                <div className="rect1" />
              </div>
              <div className="col">
                <span className="title2">5-15%</span>
                <div className="rect2" />
              </div>
            </div>
            <div className="line" />
          </div>
          <img className="close" alt="" src={Images.affiStats.affiStats7} />
        </div>
      </Flip>

      <div className="stat-row-2">
        <Flip bottom>
          <div className="stat2">
            <div className="bg">
              <img alt="" src={Images.affiStats.affiStats2} />
            </div>
            <div className="content">
              <img className="logo" alt="" src={Images.Homepage.affiliate5} />
              <div className="extra">
                <img alt="" src={Images.affiStats.affiStats9} />
                <span>Được cấp quyền trải nghiệm</span>
              </div>
              <span className="title">App Lanatomy AI</span>
            </div>
            <img className="star" alt="" src={Images.affiStats.affiStats3} />
          </div>
        </Flip>

        <Flip bottom>
          <div className="stat3">
            <div className="content">
              <div className="extra">
                <img alt="" src={Images.affiStats.affiStats6} />
                <span>Học tập kinh nghiệm mới</span>
              </div>
              <span className="title1">
                Được <span className="highlight">đào tạo</span> bởi
              </span>
              <span className="title2">Team Lanatomy AI</span>
            </div>
            <img className="avatar" alt="" src={Images.affiStats.affiStats4} />
            <img className="star" alt="" src={Images.affiStats.affiStats3} />
          </div>
        </Flip>
      </div>
    </div>
  );
}
