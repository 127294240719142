import Footer from "../Footer";
import Navigation from "../homepage/components/Navigation";

function DeleteAccount() {
  return (
    <div className="root-app" id="root-app">
      <div className={"homepage" + " affiliate"} id="top">
        <Navigation isHome={false} />
      </div>
      <div>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScoEKP5n0x2vQnBYTTzHgg4qdMqFhJ-ftUxNdz1OZPc05LJ2g/viewform?embedded=true"
          width="100%"
          height={1800}
          frameborder="0"
        >
          Loading…
        </iframe>
      </div>
      <Footer />
    </div>
  );
}

export default DeleteAccount;
