import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import { Images } from "../../utils";
import "./index.scss";

export default function Introduction3() {
  return (
    <div className="introduction3">
      <div className="content-wrap">
        <Fade bottom delay={300}>
          <div className="title">
            Có gì bên trong thẻ từ của
            <img alt="" src={Images.Introduction3.intro2} />
          </div>
          <span className="title">Lanatomy AI?</span>
        </Fade>
        <Fade bottom delay={300}>
          <span className="detail">
            Lanatomy AI gồm khối lượng lớn thẻ từ vựng chuẩn chỉnh do người Việt
            soạn cho người Việt học, và trải qua 3 vòng kiểm tra chất lượng bởi
            team Hana's Lexis.
          </span>
        </Fade>
      </div>
      <div className="image-wrap">
        <Zoom>
          <div className="ball" />
        </Zoom>
        <Fade right>
          <img alt="" className="intro1" src={Images.Introduction3.intro1} />
        </Fade>
      </div>
    </div>
  );
}
