import "./index.scss";
import Navigation from "./components/Navigation";
import HomeContent from "./components/HomeContent";
import { Images } from "../../utils";
import Fade from "react-reveal/Fade";
import AffiliateContent from "./components/AffiliateContent";

export default function Homepage({ isHome = true }) {
  return (
    <div className={"homepage" + (isHome ? "" : " affiliate")} id="top">
      {isHome && (
        <div className="home-bg">
          <div className="top-linear" />
          <Fade bottom>
            <div
              style={{
                background: `url(${Images.Homepage.HomeBg})`,
                mixBlendMode: "screen",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
          </Fade>
        </div>
      )}
      <Navigation isHome={isHome} />
      {isHome ? <HomeContent /> : <AffiliateContent />}
    </div>
  );
}
