import Images from "../../../utils/images";
import "./index.scss";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";

export default function AffiliateBenefit() {
  return (
    <div className="AffiliateBenefit">
      <div className="left-content">
        <Bounce>
          <div className="extra">
            <img alt="" src={Images.affiBenefit.benefit1} />
            <span>Bạn nhận được gì khi tham gia?</span>
          </div>
        </Bounce>
        <Fade>
          <span className="title1">Hoa hồng</span>
        </Fade>
        <Fade>
          <span className="title2">cạnh tranh</span>
        </Fade>
        <Fade>
          <img className="line" alt="" src={Images.affiBenefit.benefit2} />
        </Fade>
        <Flip>
          <div className="number-percent">
            <span>từ 5-15%</span>
          </div>
        </Flip>
        <Fade>
          <span className="detail">
            cho mỗi người mua app thành công qua lời giới thiệu của bạn, có thể
            theo gói năm, gói trọn đời hoặc tùy chỉnh theo độ dài thời gian
            người mua cần học app
          </span>
        </Fade>
      </div>
      <Zoom>
        <div className="right-content">
          <img alt="" src={Images.affiBenefit.benefit3} />
        </div>
      </Zoom>
    </div>
  );
}
