import { Images } from "../../utils";
import "./index.scss";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

export default function RandomReview() {
  return (
    <div className="randomReview">
      <div className="review1">
        <Zoom delay={300}>
          <div className="ball" />
        </Zoom>
        <Zoom delay={300}>
          <div className="ball1" />
        </Zoom>
        <Zoom delay={300}>
          <div className="ball2" />
        </Zoom>
        <Fade bottom>
          <img alt="" src={Images.randomReview.review1} />
        </Fade>
      </div>
      <div className="content">
        <Fade bottom delay={300}>
          <span className="title">Xây dựng kho từ của riêng bạn</span>
        </Fade>
        <Fade bottom delay={300}>
          <span className="detail">
            Tính năng lưu thẻ từ vào kho riêng cho phép bạn dễ dàng tìm kiếm và
            xem lại từ vựng đã học. Cũng như ôn tập nhanh những thẻ từ theo mong
            muốn.
          </span>
        </Fade>
        <Fade bottom>
          <img alt="" className="review3" src={Images.randomReview.review3} />
          <div className="line-right" />
          <div className="point-right" />
          <div className="line-left" />
          <div className="line-left2" />
          <div className="point-left" />
        </Fade>
      </div>
      <div className="review2-wrap">
        <Fade bottom>
          <img alt="" className="review2" src={Images.randomReview.review2} />
        </Fade>
        <Fade bottom delay={300}>
          <div className="note">
            <img alt="" src={Images.randomReview.line} />
            <span>Chọn đúng từ muốn ôn nhanh</span>
          </div>
        </Fade>
      </div>
    </div>
  );
}
