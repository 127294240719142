const Images = {
  Homepage: {
    LogoHeading: require("../assets/images/homepage/LogoHeading.svg").default,
    Logo: require("../assets/images/homepage/Logo.svg").default,
    title2: require("../assets/images/homepage/title2.svg").default,
    Logo2: require("../assets/images/homepage/Logo2.svg").default,
    MobileIcon: require("../assets/images/homepage/mobile_icon.svg").default,
    //AppName: require("../assets/images/homepage/AppName.svg").default,
    ScrollDown: require("../assets/images/homepage/ScrollDown.svg").default,
    HomeBg: require("../assets/images/homepage/HomeBg.png"),
    DemoApp: require("../assets/images/homepage/DemoApp.png"),
    DowloadIOS: require("../assets/images/homepage/DowloadIOS.png"),
    DowloadAndroid: require("../assets/images/homepage/DowloadAndroid.png"),
    AppleStore: require("../assets/images/homepage/AppleStore.svg").default,
    GooglePlay: require("../assets/images/homepage/GooglePlay.svg").default,
    affiliate1: require("../assets/images/homepage/affiliate1.png"),
    affiliate2: require("../assets/images/homepage/affiliate2.png"),
    affiliate3: require("../assets/images/homepage/affiliate3.png"),
    affiliate4: require("../assets/images/homepage/affiliate4.png"),
    affiliate5: require("../assets/images/homepage/affiliate5.png"),
    affiliate6: require("../assets/images/homepage/affiliate6.png"),
    affiliate7: require("../assets/images/homepage/affiliate7.svg").default,
  },
  Introduction: {
    screen: require("../assets/images/introduction/screen.png"),
    screen2: require("../assets/images/introduction/screen2.png"),
    line: require("../assets/images/introduction/line.svg").default,
  },
  Introduction2: {
    intro1: require("../assets/images/introduction2/intro1.png"),
    intro2: require("../assets/images/introduction2/intro2.png"),
    intro3: require("../assets/images/introduction2/intro3.png"),
    stampt: require("../assets/images/introduction2/stampt.png"),
    line: require("../assets/images/introduction2/line.svg").default,
  },
  Introduction3: {
    intro1: require("../assets/images/introduction3/intro1.png"),
    intro2: require("../assets/images/introduction3/intro2.png"),
  },
  footer: {
    title: require("../assets/images/footer/title.svg").default,
    instagram: require("../assets/images/footer/instagram.svg").default,
    facebook: require("../assets/images/footer/facebook.svg").default,
    email: require("../assets/images/footer/email.svg").default,
    pinteres: require("../assets/images/footer/pinteres.svg").default,
    backToTop: require("../assets/images/footer/backToTop.svg").default,
  },
  story: {
    storyDemo: require("../assets/images/story/storyDemo.png"),
    story1: require("../assets/images/story/story1.png"),
    story2: require("../assets/images/story/story2.png"),
    story5: require("../assets/images/story/story5.png"),
    story6: require("../assets/images/story/story6.svg").default,
    next: require("../assets/images/story/next.svg").default,
    line: require("../assets/images/story/line.svg").default,
  },
  randomReview: {
    review1: require("../assets/images/randomReview/review1.png"),
    review2: require("../assets/images/randomReview/review2.png"),
    review3: require("../assets/images/randomReview/review3.png"),
    line: require("../assets/images/randomReview/line.svg").default,
  },
  subscription: {
    check: require("../assets/images/subscription/check.svg").default,
    notCheck: require("../assets/images/subscription/not_check.svg").default,
  },
  affiBenefit: {
    benefit1: require("../assets/images/affiBenefit/benefit1.svg").default,
    benefit2: require("../assets/images/affiBenefit/benefit2.png"),
    benefit3: require("../assets/images/affiBenefit/benefit3.png"),
  },
  affiStats: {
    affiStats1: require("../assets/images/affiStats/affiStats1.svg").default,
    affiStats2: require("../assets/images/affiStats/affiStats2.png"),
    affiStats3: require("../assets/images/affiStats/affiStats3.png"),
    affiStats4: require("../assets/images/affiStats/affiStats4.png"),
    affiStats5: require("../assets/images/affiStats/affiStats5.png"),
    affiStats6: require("../assets/images/affiStats/affiStats6.png"),
    affiStats7: require("../assets/images/affiStats/affiStats7.png"),
    affiStats8: require("../assets/images/affiStats/affiStats8.png"),
    affiStats9: require("../assets/images/affiStats/affiStats9.png"),
  },
  affiReason: {
    affiReason1: require("../assets/images/affiReason/affiReason1.png"),
    affiReason2: require("../assets/images/affiReason/affiReason2.png"),
    affiReason3: require("../assets/images/affiReason/affiReason3.png"),
    affiReason4: require("../assets/images/affiReason/affiReason4.svg").default,
    affiReason5: require("../assets/images/affiReason/affiReason5.svg").default,
    affiReason6: require("../assets/images/affiReason/affiReason6.svg").default,
    affiReason7: require("../assets/images/affiReason/affiReason7.svg").default,
    affiReason8: require("../assets/images/affiReason/affiReason8.svg").default,
    affiReason9: require("../assets/images/affiReason/affiReason9.svg").default,
    affiReason10: require("../assets/images/affiReason/affiReason10.svg").default,
    affiReason11: require("../assets/images/affiReason/affiReason11.png"),
    affiReason12: require("../assets/images/affiReason/affiReason12.svg").default,
    affiReason13: require("../assets/images/affiReason/affiReason13.svg").default,
  },
};

export default Images;
