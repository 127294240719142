import { Images } from "../../utils";
import { APPSTORE, PLAYSTORE } from "../../utils/common";
import "./index.scss";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";

export default function Introduction() {
  return (
    <div className="introduction">
      <div className="content-wrap">
        <Fade bottom delay={300}>
          <span className="title">Hết thắc mắc nhờ A.I.</span>
        </Fade>
        <Fade bottom delay={300}>
          <span className="detail">
            Bobbi - chatbot A.I. trả lời mọi câu hỏi của bạn trong quá trình học
            tiếng Anh về từ vựng, ngữ pháp, văn hóa, v.v.
          </span>
          <span className="detail">
            Bạn có thể chat với Bobbi bằng tiếng Anh hay tiếng Việt tùy theo nhu
            cầu học tập.{" "}
          </span>
        </Fade>

        <div className="btn-wrap">
          <Bounce delay={500}>
            <img
              alt=""
              src={Images.Homepage.GooglePlay}
              onClick={() => window.open(PLAYSTORE)}
            />
          </Bounce>
          <Bounce delay={500}>
            <img
              alt=""
              src={Images.Homepage.AppleStore}
              onClick={() => window.open(APPSTORE)}
            />
          </Bounce>
        </div>
      </div>
      <div className="image-wrap">
        <Fade right delay={300}>
          <img alt="" className="screen1" src={Images.Introduction.screen} />
        </Fade>
        <Zoom delay={1000}>
          <img alt="" className="screen2" src={Images.Introduction.screen2} />
        </Zoom>
        <Fade bottom delay={300}>
          <img alt="" className="line" src={Images.Introduction.line} />
        </Fade>
      </div>
    </div>
  );
}
