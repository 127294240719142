import "./index.scss";

export default function TermsOfService() {
  return (
    <div className="TermsOfService">
      {/* page 1 */}
      <h1>Terms of Service</h1>
      <p>
        Welcome to the Lanatomy AI website (the “Site”). The Site is owned and
        operated by Lanatomy AI. (“Lanatomy AI,” “Company”, “we”, or “us”). We
        are making the Site and our mobile applications (“Apps”) (collectively,
        the “Service”) available to you subject to the following terms and
        conditions of service (the “Terms”). The Terms are a legal contract
        between you and Lanatomy AI regarding your use of the Service (you and
        others using the Service will be referred to as “Users”).
      </p>
      <p>
        As long as you comply with these Terms, you have the right to download
        and install a copy of the Apps to your mobile device, and to access and
        use the Service, for your own personal use. By accessing and using the
        Service, you acknowledge that you have read, understood, and agree to be
        bound by the Terms. If you do not agree to these Terms at any time,
        please do not use the Service.
      </p>
      <h2>Applicable Terms and Policies</h2>
      <p>
        <strong>Guidelines</strong>. When using the Service, you will be subject
        to any additional posted guidelines or rules applicable to specific
        services and features which may be posted from time to time (the
        “Guidelines”). All such Guidelines are hereby incorporated by reference
        into the Terms.
      </p>
      <p>
        <strong>Privacy Policy</strong>. Please read our Privacy Policy
        carefully for details relating to what information and data we collect
        from you and other Users, and how we use that information internally and
        disclose it to third parties, including but not limited to advertisers.
        Our Privacy Policy is incorporated into these Terms by reference.
      </p>
      <p>
        <strong>Modifications</strong>. From time to time, we may modify the
        Terms. If we do so, we will make the modified Terms available through
        the Service and indicate the date of the latest revision. We encourage
        you to review the Terms periodically for changes. Your continued use of
        the Service after revised Terms have become effective indicates that you
        have read, understood, and agreed to the current version of the Terms.
        If you do not agree with any changes to the Terms, you must cease using
        the Service.
      </p>
      <p>
        <strong>Termination</strong>. You agree that we may terminate your
        account or use of the Service at any time in our sole discretion, and
        you agree that we shall not be liable to you or any third party for any
        such termination. If we terminate your access to the Service, your
        Content will no longer be accessible through your account, but such
        Content may persist and appear within the Service (e.g. if your Content
        has been shared or re-shared).
      </p>
      {/* page 2 */}
      <h2>Your Account</h2>
      <p>
        <strong>Account Information:</strong> In order to use some features of
        the Service, you will have to create an account. When creating an
        account, you must provide true, accurate, current, and complete
        information. You also must ensure that this information is kept accurate
        and up-to-date at all times. You agree that we may access, preserve and
        disclose your account information and Your Content if required to do so
        by law or in a good faith belief that such access, preservation or
        disclosure is reasonably necessary to (a) comply with legal process; (b)
        enforce the Terms; (c) respond to a claim that Your Content violates the
        rights of third parties; (d) to respond if you contact us; or (e)
        protect the rights, property or personal safety of the Company, Users
        and the public.
      </p>
      <p>
        <strong>Password:</strong> When you register, you will be asked to
        provide a password. As you will be responsible for all activities that
        occur under your password, you should keep your password confidential.
        You are solely responsible for maintaining the confidentiality of your
        account and password and for restricting access to your computers and
        mobile devices, and you agree to accept responsibility for all
        activities that occur under your account or password. If you have reason
        to believe that your account is no longer secure (for example, in the
        event of a loss, theft, or unauthorized disclosure or use of your
        account ID or password), you should immediately notify us. You may be
        liable for the losses incurred by us or others due to any unauthorized
        use of your account.
      </p>
      <p>
        <strong>Prohibitions:</strong> When creating an account, you may not:
        (a) provide any false personal information to us (including a false
        username) or create any account for anyone other than yourself without
        such other person’s permission; (b) use a username that is the name of
        another person with the intent to impersonate that person; (c) use a
        username that is subject to rights of another person without appropriate
        authorization; or (d) use a username that is offensive, vulgar or
        obscene or otherwise in bad taste. We reserve the right to suspend or
        terminate your account if any information provided during the
        registration process or thereafter proves to be inaccurate, false or
        misleading or to reclaim any username that you create through the
        Service that violates our Terms.
      </p>
      {/* page 3 */}
      <h2>Your Content</h2>
      <p>
        <strong>General:</strong> We may now or in the future permit the
        submission and posting of or linking to works of authorship, creative
        works, graphics, images, photos, video, audio, transcriptions of
        meetings, text, interactive features, Computed metrics of the audio
        data, (“Content”) submitted by you. We may or may not use Your Content
        in our sole discretion. You understand that whether or not Your Content
        is published, we do not guarantee confidentiality with respect thereto.
        To the extent you desire to use the Service in connection with
        information located on your other third-party accounts, you hereby grant
        Lanatomy AI permission to access the Third Party Accounts in connection
        with the provision of the Service.
      </p>
      <p>
        At your request, you can delete your account in its entirety by emailing
        us at <a href="mailto:help@lanatomy.ai">help@lanatomy.ai</a>
      </p>
      <p>
        <strong>Grant of Rights:</strong> We may use Your Content in a number of
        different ways, including publicly displaying it, reformatting it, using
        it to improve our technology, creating derivative works from it,
        distributing it, and allowing others to do the same in connection with
        their own websites and media platforms. By submitting Your Content to
        the Service, you hereby grant Lanatomy AI and its affiliates a worldwide,
        non-exclusive, fully paid-up, royalty-free, irrevocable, transferable
        license, with the right to grant and authorize sublicenses, to use,
        reproduce, distribute, modify, adapt, prepare derivative works of,
        display, perform, and otherwise exploit Your Content in connection with
        the Service and our business, including without limitation for promoting
        and redistributing part or all of the Service (and derivative works
        thereof) in any media formats and through any media channels now known
        or hereafter discovered. You grant Lanatomy AI, its affiliates, and
        sublicensees the right to use the name that you submit in connection
        with Your Content if they choose. You also agree to irrevocably waive
        any claims and assertions of moral rights or attribution with respect to
        Your Content. You also hereby grant to each User of the Service a
        non-exclusive license to access Your Content through the Service, and to
        use, reproduce, distribute, prepare derivative works of, display, and
        perform Your Content as permitted by the functionality of the Service
        and these Terms. Except for the limited rights set forth in these Terms,
        you retain all right, title, and interest in Your Content.
      </p>
      <p>
        <strong>Representations and Warranties Regarding Your Content:</strong>{" "}
        You represent and warrant that: (a) you own Your Content or you have the
        right to use it and grant us the rights and license as provided in these
        Terms, and (b) the sharing of your Content on or through the Service
        does not violate the privacy rights, publicity rights, copyrights,
        contract rights or any other rights of any person. You acknowledge and
        agree that you are solely responsible for providing any notices to, and
        obtaining consent from, individuals in connection with any recordings of
        conversations as required under applicable law in the country or state
        you and or the other parties reside in.
      </p>
      <p>
        <strong>Prohibitions:</strong> When submitting Content to or otherwise
        using the Service, you agree not to: (a) submit material that is
        unlawful, obscene, defamatory, libelous, threatening, pornographic,
        harassing, hateful, racially or ethnically offensive, or encourages
        conduct that would be considered a criminal offense, give rise to civil
        liability, violate any law, or is otherwise inappropriate; (b) submit
        falsehoods or misrepresentations that could damage us, our Users or any
        third party; (c) submit any private information of someone, like their
        address, phone number, email address, and similar information without
        their permission; (d) submit material that violates a third party’s
        proprietary rights, including privacy and publicity rights, or that
        otherwise violates any applicable law; (e) post advertisements; or (f)
        upload Content that would be harmful to minors in any manner.
      </p>
      <p>
        <strong>Our Right to Monitor, Edit and Remove Content:</strong> We don’t
        have an obligation to monitor your use of the Service or to review or
        any Content, but we have the right to monitor, remove, edit, and block
        Content or accounts containing Content for the purpose of operating the
        Service, to ensure your compliance with these Terms, or to comply with
        applicable law or the order or requirement of a court, administrative
        agency or other governmental body. We reserve the right, at any time and
        without prior notice, to remove or disable access to any Content that we
        consider, in our sole discretion, to be in violation of these Terms or
        otherwise harmful to the Service.
      </p>
      <p>
        Lanatomy AI shall have the right to collect and analyze data about the
        performance of the Service and related systems. Nothing in these Terms
        prohibits the Company from using Machine Learning to optimize the
        Service.
      </p>
      {/* page 4 */}
      <h2>Our Proprietary Rights</h2>
      <p>
        <strong>Ownership:</strong> Our Service is protected by copyright,
        trademark, and other laws of the United States and foreign countries.
        Except as expressly provided in these Terms, we (or our licensors)
        exclusively own all right, title, and interest in and to the Service,
        including all associated intellectual property rights. You may not
        remove, alter, or obscure any copyright, trademark, service mark, or
        other proprietary rights notices incorporated in or accompanying the
        Service, including in any Content. You acknowledge and agree that any
        feedback, comments, or suggestions you may provide regarding the Service
        will be the sole and exclusive property of Lanatomy AI and you hereby
        irrevocably assign to us all of your right, title, and interest in and
        to the foregoing.
      </p>
      <p>
        <strong>Restrictions:</strong> You may not: (a) copy, modify, or
        distribute the Service for any purpose; (b) transfer, sublicense, lease,
        lend, rent, or otherwise distribute the Service to any third party; (c)
        decompile, reverse-engineer, disassemble, or create derivative works of
        the Service, or otherwise attempt to discover the source code of the
        Service; (d) make the functionality of the Service available to multiple
        users through any means; or (e) use the Service in any unlawful manner,
        for any unlawful purpose, or in any manner inconsistent with these
        Terms.
      </p>
      <h2>Restrictions</h2>
      <p>You agree not to:</p>
      <ul>
        <li>
          (a) interfere with, damage, impair, or disable the Service’s
          operation, by any means (whether through automated means or
          otherwise), including uploading or otherwise disseminating viruses,
          worms, spyware, adware, or other malicious code;
        </li>
        <li>
          (b) make unsolicited offers, advertisements, proposals, or send junk
          mail or spam to other Users, or use the Service for commercial
          purposes;
        </li>
        <li>
          (c) use any robot, spider, scraper, or other automated means to access
          the Service for any purpose without our express consent or bypass our
          robot exclusion headers or similar measures;
        </li>
        <li>
          (d) remove, circumvent, disable, damage, or otherwise interfere with
          the Service’s security-related features, features that prevent or
          restrict the use or copying of any part of the Service, or features
          that enforce Service limitations;
        </li>
        <li>
          (e) attempt to gain unauthorized access to the Service, other User
          accounts, computer systems, or networks connected to the Service
          through hacking, password mining, or any other means;
        </li>
        <li>
          (f) deep-link to the Service, and you agree you will promptly remove
          any links that Company finds objectionable in its sole discretion;
        </li>
        <li>
          (g) send bulk emails, surveys, or other mass messaging, whether
          commercial in nature or not;
        </li>
        <li>
          (h) solicit personal information from minors, or submit or transmit
          pornography;
        </li>
        <li>(i) reformat or frame any portion of the Service;</li>
        <li>
          (k) take any action that imposes, or may impose, in our sole
          discretion, an unreasonable or disproportionately large load on our
          technology infrastructure or otherwise make excessive traffic demands
          of the Service;
        </li>
        <li>
          (l) impersonate another person or represent yourself as affiliated
          with us, our staff, or other industry professionals;
        </li>
        <li>(m) solicit a User’s password or other account information; or</li>
        <li>
          (n) harvest User names, addresses, or email addresses for any purpose.
        </li>
      </ul>
      <p>
        The Subscription Contract Between You And Us Lanatomy AI may offer various
        subscription types: including monthly yearly subscriptions
        (“Month-to-Month Subscriptions”, “Year-to-Year Subscriptions”); prepaid
        three, six, twelve, twenty four-month and lifetime subscriptions
        (“Prepaid Subscriptions”);
      </p>
      {/* page 5 */}
      <h2>Automatic Renewal Terms</h2>
      <p>
        With respect to Lanatomy AI subscriptions subject to automatic renewal, you
        agree that once you have expressly agreed for your credit card to be
        charged on a recurring basis, and agreed to the amount of the recurring
        charges, Lanatomy AI may submit periodic charges (e.g., monthly) to your
        chosen payment method without further authorization from you, until you
        provide prior notice at any time that you wish to terminate this
        authorization or to change your payment method. You agree that such
        notice will not affect charges submitted before Lanatomy AI reasonably
        could act. Information on how to cancel is described below.
      </p>
      <h2>MONTH-TO-MONTH OR YEAR-TO-YEAR REBILLABLE SUBSCRIPTIONS</h2>
      <p>
        By purchasing a Month-to-Month or Year-to-Year Rebillable Subscription,
        you agree and acknowledge that your subscription has an initial and
        recurring payment charge at the then-current subscription rate and you
        accept responsibility for all recurring charges prior to cancellation,
        including where applicable any charges processed by Lanatomy AI after the
        expiration date of your payment card.
      </p>
      <h3>Automatic Monthly and Yearly Renewal Terms</h3>
      <p>
        Your subscription will be automatically renewed for successive monthly
        or yearly periods and your payment method will automatically be charged
        for each successive monthly or yearly period at the then-current
        subscription rate until you cancel your Month-to-Month or Year-to-Year
        Rebillable Subscription renewal.
      </p>
      <h3>
        Cancellation Policy For Month-to-Month or Year-to-Year Rebillable
        Subscription Renewals
      </h3>
      <p>
        To cancel your Month-to-Month or Year-to-Year Rebillable Subscription
        please cancel via the App store/Google play.
      </p>
      <p>
        If you cancel the auto-renewal, your subscription will remain active
        until the end of your then-current subscription term.
      </p>
      <h2>POLICY FOR REFUND SUBSCRIPTIONS OF LANATOMY AI</h2>
      <h3>
        1.1 For in-app purchases from Apple (iOS devices) and Google Play
        (Android devices):
      </h3>
      <p>
        If you made a purchase on the Lanatomy AI mobile app, your purchase was
        processed using either Google Play Billing (for Android devices) or
        Apple Pay (for iOS devices).
      </p>
      <p>
        In-app payments are processed through Apple or Google and not Lanatomy AI,
        so Lanatomy AI is unable to offer refunds for in-app purchases made in the
        Apple App Store or Google Play Store.
      </p>
      <p>
        Buyers can submit refund requests to Apple/Google Play directly. It is
        at the processor’s discretion (i.e., Google Play or Apple) to process
        refund requests based on their policies. Lanatomy AI will not be held
        responsible for any denied refund requests by Apple/Google.
      </p>
      Here is the refund process from Apple and Google Play for your reference:
      <li>
        <a href="https://support.apple.com/en-vn/HT204084">
          Request a refund for apps or content that you bought from Apple
        </a>
      </li>
      <li>
        <a href="https://support.google.com/googleplay/workflow/9813244?p=refundAWF&visit_id=637701570361413319-1908625833&rd=1">
          Request a refund for apps or content that you bought from Google Play
        </a>
      </li>
      {/* page 7 */}
      <h3>1.2 For purchases made on the Lanatomy AI website:</h3>
      <p>
        All charges for purchases made through the Lanatomy AI website are
        refundable by Lanatomy AI if buyers submit the refund request within seven
        days of purchase to{" "}
        <a href="mailto:help@lanatomy.ai">help@lanatomy.ai</a>.
      </p>
      <h4>1.2.1 Refund method and time:</h4>
      <p>
        Lanatomy AI will issue a refund via the original payment method. This
        process takes approximately 30 business days (excluding Saturdays,
        Sundays, and holidays), depending on the initial payment methods of the
        buyers.
      </p>
      <h3>Indemnification; Hold Harmless</h3>
      <p>
        You agree to defend (at our request), indemnify and hold harmless
        Lanatomy AI and its employees, managers, officers and agents (collectively,
        the “Lanatomy AI Parties”) from and against any claims, liabilities,
        damages, losses, and expenses, including without limitation, reasonable
        attorney’s fees and costs, arising out of or in any way connected with
        any of the following (including as a result of your direct activities on
        the Service or those conducted on your behalf): (i) your Content or your
        access to or use of the Service; (ii) your breach or alleged breach of
        these Terms of Use; (iii) your violation of any third-party right,
        including without limitation, any intellectual property right,
        publicity, confidentiality, property or privacy right; (iv) your
        violation of any laws, rules, regulations, codes, statutes, ordinances
        or orders of any governmental and quasi-governmental authorities,
        including, without limitation, all regulatory, administrative and
        legislative authorities; or (v) any misrepresentation made by you. You
        will cooperate as fully required by the Company in the defense of any
        claim. We reserve the right to assume the exclusive defense and control
        of any matter subject to indemnification by you, and you will not in any
        event settle any claim without our prior written consent.
      </p>
      {/* page 8 */}
      <h3>Disclaimer of Warranties</h3>
      <ul>
        <p>
          <li>
            <strong>Warranty Disclaimer</strong>. YOU ACKNOWLEDGE THAT YOUR USE
            OF THE SERVICE IS AT YOUR SOLE RISK. THE LANATOMY AI PARTIES DISCLAIM
            ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER VIRUS. THE SERVICE IS
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST
            EXTENT PERMISSIBLE BY LAW, THE LANATOMY AI PARTIES DO NOT MAKE ANY
            REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS AS TO: (A) THE
            SERVICE; (B) THE LANATOMY AI CONTENT; (C) USER OR THIRD PARTY CONTENT;
            OR (D) SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO
            LANATOMY AI OR VIA THE SERVICE.
          </li>
        </p>
        <p>
          <li>
            <strong>No Technical Warranties</strong>. THE LANATOMY AI PARTIES DO
            NOT REPRESENT OR WARRANT THAT (A) THE SERVICE WILL BE ERROR-FREE OR
            UNINTERRUPTED, (B) DEFECTS WILL BE CORRECTED, (C) THE SERVICE OR THE
            SERVER THAT MAKES THE SERVICE AVAILABLE IS FREE FROM ANY HARMFUL
            COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES, OR (D) THE
            INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE SERVICE IS ACCURATE,
            COMPLETE, OR USEFUL.
          </li>
        </p>
        <p>
          <li>
            <strong>Certain Jurisdictions</strong>. THE LANATOMY AI PARTIES DO NOT
            WARRANT THAT YOUR USE OF THE SERVICE IS LAWFUL IN ANY PARTICULAR
            JURISDICTION, AND THE LANATOMY AI PARTIES SPECIFICALLY DISCLAIM SUCH
            WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER
            OF IMPLIED OR OTHER WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT
            APPLY TO YOU TO THE EXTENT SUCH JURISDICTION’S LAW IS APPLICABLE TO
            YOU AND THESE TERMS OF USE. BY ACCESSING OR USING THE SERVICE YOU
            REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY
            JURISDICTION WHERE YOU ACCESS OR USE THE SERVICE.
          </li>
        </p>
      </ul>
      {/* page 9 */}
      <h3>Limitation of Liability; Waiver</h3>
      <ul>
        <p>
          <li>
            <strong>No Liability for Losses or Damages.</strong> UNDER NO
            CIRCUMSTANCES WILL THE LANATOMY AI PARTIES BE LIABLE FOR ANY DIRECT,
            INDIRECT, ECONOMIC, EXEMPLARY, SPECIAL, PUNITIVE, INCIDENTAL OR
            CONSEQUENTIAL LOSSES OR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS
            OR DAMAGE DIRECTLY OR INDIRECTLY RELATED TO: (A) THE SERVICE AND ITS
            OPERATION; (B) THE LANATOMY AI CONTENT; (C) USER OR THIRD PARTY
            CONTENT; (D) YOUR INABILITY TO USE THE SERVICE; (E) ANY ACTION TAKEN
            IN CONNECTION WITH AN INVESTIGATION BY THE LANATOMY AI PARTIES OR LAW
            ENFORCEMENT AUTHORITIES REGARDING YOUR OR ANY OTHER PARTY’S USE OF
            THE SERVICE; (F) ANY DAMAGE TO ANY USER’S COMPUTER, MOBILE DEVICE,
            OR OTHER EQUIPMENT OR TECHNOLOGY; OR (G) LOST PROFITS, LOSS OF
            GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF RESULTS, DEATH,
            PERSONAL INJURY, OR COMPUTER FAILURE OR MALFUNCTION. THE LANATOMY AI 
            PARTIES SHALL NOT BE LIABLE FOR THE FOREGOING LOSSES OR DAMAGES (X)
            EVEN IF FORESEEABLE OR EVEN IF THE LANATOMY AI PARTIES HAVE BEEN
            ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES,
            AND (Y) WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, STRICT
            LIABILITY OR TORT (INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED IN
            WHOLE OR IN PART BY NEGLIGENCE, ACTS OF GOD, TELECOMMUNICATIONS
            FAILURE, OR THEFT OR DESTRUCTION OF THE SERVICE).
          </li>
        </p>
        <p>
          <li>
            <strong>State Requirements.</strong> SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
            THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </li>
        </p>
        <p>
          <li>
            <strong>Monetary Limitation.</strong> IN NO EVENT WILL THE LANATOMY AI 
            PARTIES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OR
            ACTION EXCEED ONE HUNDRED UNITED STATES DOLLARS ($100.00).
          </li>
        </p>
        <p>
          <li>
            <strong>No Injunctions.</strong> YOU AGREE THAT IN THE EVENT YOU
            INCUR ANY DAMAGES, LOSSES OR INJURIES THAT ARISE OUT OF THE ACTS OR
            OMISSIONS OF THE LANATOMY AI PARTIES, THE DAMAGES, IF ANY, CAUSED TO
            YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN
            INJUNCTION OF ANY KIND.
          </li>
        </p>
        <p>
          <li>
            <strong>Waiver of California Civil Code Section 1542.</strong> BY
            ACCESSING THE SERVICE, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS
            WITH RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED,
            AND IN ACCORDANCE WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE
            READ AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF
            SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW OF
            ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS: “A GENERAL
            RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW
            OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
            RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
            SETTLEMENT WITH THE DEBTOR.”
          </li>
        </p>
        <p>
          <li>
            <strong>Acts or Omissions of Third Parties.</strong> LANATOMY AI IS NOT
            RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD
            PARTIES, AND YOU RELEASE THE LANATOMY AI PARTIES FROM ANY CLAIMS AND
            DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES.
          </li>
        </p>
      </ul>
      {/* page 10 */}
      <h3>Notice and Procedure for Making Claims of Copyright Infringement</h3>
      <p>
        Notification of Infringement. Lanatomy AI respects the intellectual
        property of others, and we ask our users to do the same. If you believe
        that your work has been copied in a way that constitutes copyright
        infringement, please notify the Company of your infringement claim and
        provide us with the below information in accordance with the procedure
        set below. Company will process and investigate notices of alleged
        infringement and will take appropriate actions under the Digital
        Millennium Copyright Act (“DMCA”) and other applicable intellectual
        property laws with respect to any alleged or actual infringement. A
        notification of claimed copyright infringement should be emailed to
        Company’s Copyright Agent at (Subject line: “DMCA Takedown Request”).
        You may also contact us by mail at{" "}
        <a href="mailto:help@lanatomy.ai">help@lanatomy.ai</a>.
      </p>
      <p>
        To be effective, the notification must be in writing and contain the
        following information:
      </p>
      <ul>
        <li>
          An electronic or physical signature of the person authorized to act on
          behalf of the owner of the copyright interest;
        </li>
        <li>
          A description of the copyrighted work that you claim has been
          infringed upon;
        </li>
        <li>
          A description of where the material that you claim is infringing is
          located on the site, with enough detail that we may find it on the
          site;
        </li>
        <li>Your address, telephone number, and e-mail address;</li>
        <li>
          A statement by you that you have a good-faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
        </li>
        <li>
          A statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright
          owner or authorized to act on the copyright owner’s behalf.
        </li>
      </ul>
      <p>
        Please note that this procedure is exclusively for notifying Lanatomy AI 
        that your copyrighted material has been infringed.
      </p>
      <h2>Miscellaneous</h2>
      <p>
        These Terms are the entire agreement between the parties on the subject
        matter hereof. The relationship of the parties hereunder is that of
        independent contractors, and these Terms will not be construed to imply
        that either party is the agent, employee, or joint venturer of the
        other. In the event that any provision of these Terms is held to be
        unenforceable, these Terms will continue in full force and effect
        without said provision and will be interpreted to reflect the original
        intent of the parties. You may not assign these Terms without the prior
        written consent of Company, and any prohibited assignment will be null
        and void. Waiver by either party of a breach of any provision of these
        Terms or the failure by either party to exercise any right hereunder
        will not operate or be construed as a waiver of any subsequent breach of
        that right or as a waiver of any other right. YOU AGREE THAT ANY CAUSE
        OF ACTION BROUGHT BY YOU ARISING OUT OF OR RELATED TO THE SERVICE MUST
        COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.
        OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
      </p>
      <p
        style={{
          paddingBottom: 40,
        }}
      >
        Last Updated: July 20, 2023
      </p>
    </div>
  );
}
