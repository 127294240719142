import "./index.scss";

export default function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      {/* page 1 */}
      <h1>PRIVACY NOTICE</h1>
      <p>
        This privacy notice for LANATOMY AI ("we," "us," or "our") describes how
        and why we might collect, store, use, and share ("process") your
        information when you use our services ("Services"), such as when you:
      </p>
      <ul>
        <li>
          Visit our website at <a href="http://lanatomy.ai">lanatomy.ai</a>
        </li>
        <li>Download and use our mobile application (Lanatomy AI)</li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>

      <p>
        Questions or concerns? Reading this privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at{" "}
        <a href="mailto:info@lanatomy.ai">info@lanatomy.ai</a>.
      </p>

      <h2>SUMMARY OF KEY POINTS</h2>
      <p>
        This summary provides key points from our privacy notice, but to make it
        easier for you here is a short overview of the most important points:
      </p>
      <ul>
        <li>To access and use Lanatomy AI, you must register for an account.</li>
        <li>
          We are committed to the protection of your privacy – your email
          address will not be used by third parties.
        </li>
        <li>
          If you want, you can turn off any notifications, emails, and
          newsletters from Lanatomy AI at any time.
        </li>
      </ul>

      <h2>WHAT WE COLLECT</h2>
      <p>We get information about you in a range of ways.</p>
      <ul>
        <li>
          <strong>Information You Give Us:</strong> We collect information you
          directly give us: your username, email address, and login credentials
          to your social network accounts.
        </li>
        <li>
          <strong>Cookies:</strong> We may log information using "cookies" for
          the sole purpose of improving our Service. Cookies are small data
          files stored on your hard drive by a website. Cookies help us make our
          Service and your visit better. We use cookies to see which parts of
          our Service people use and like and to count visits to our Service.
        </li>
        <li>
          <strong>Public Content:</strong> You can contribute to the Service in
          a number of different ways that are intended to be viewed by other
          users, including writing reviews, uploading videos and photos,
          participating in forums, tagging products, filling out your public
          profile, and voting on other users’ contributions. We may store these
          contributions (your "Public Content"), display them to other users,
          and use them for promotional purposes. Note that if you include
          personally identifiable information in your Public Content, it can be
          used and viewed by others. We are not responsible for the information
          you choose to include in your Public Content.
        </li>
      </ul>

      {/* page 2 */}
      <h1>How We Use Personal Information</h1>
      <p>We use your personal information for the following purposes:</p>
      <ul>
        <li>
          To operate, maintain, and improve our sites, products, and services.
        </li>
        <li>
          To analyze your usage of the App for the purpose of improving our App.
        </li>
        <li>
          To provide custom, personalized advertisements, content, and
          information.
        </li>
        <li>
          To monitor and analyze usage and trends, and to personalize and
          improve Lanatomy AI and your experiences when you use Lanatomy AI.
        </li>
        <li>
          To respond to comments and questions and provide customer service.
        </li>
        <li>
          To send information including confirmations, invoices, technical
          notices, updates, security alerts, and support and administrative
          messages.
        </li>
        <li>
          To protect, investigate, and deter against fraudulent, unauthorized,
          or illegal activity.
        </li>
        <li>
          To communicate about promotions, upcoming events, and other news about
          products and services offered by us and our selected partners. The
          legal bases for our processing of your personal information are (a)
          our obtaining your consent for the processing of your personal
          information for the foregoing purposes, and (b) the fact that such
          processing is necessary for us to render the Service. We retain your
          personal information to provide the Service to you and as necessary to
          comply with our legal obligations, resolve disputes, and enforce our
          agreements; otherwise, we will delete your personal information
          immediately after you terminate your account.
        </li>
      </ul>

      <h1>Sharing of Personal Information</h1>
      <p>
        In certain circumstances, we may share your personal information with
        third parties without further notice to you, unless required by the law,
        as set forth below:
      </p>
      <ul>
        <li>With your consent.</li>
        <li>For legal, protection, and safety purposes:</li>
        <ul>
          <li>To comply with laws.</li>
          <li>To respond to lawful requests and legal processes.</li>
          <li>
            To protect the rights and property of Lanatomy AI, our agents,
            customers, and others. This includes enforcing our agreements,
            policies, and terms of use.
          </li>
          <li>
            In an emergency. This includes protecting the safety of our
            employees and agents, our customers, or any person.
          </li>
        </ul>
        <li>
          If and when we negotiate a transaction involving the sale or transfer
          of all or a part of our business or assets. These transactions can
          include any merger, financing, acquisition, or bankruptcy transaction
          or proceeding.
        </li>
        <li>
          If you post Public Content on the Site or the App, we may distribute
          such Public Content to a wider audience by sharing it (or portions of
          it) with third parties so that they can publish it on their own
          websites and media platforms. We allow you to access third-party
          services, such as Facebook and Twitter, to share your selected Public
          Content with those services.
        </li>
      </ul>

      {/* page 3 */}
      <h1>Retention of Personal Information</h1>
      <p>
        We will retain your personal information for as long as your account is
        active, and as long as is reasonably necessary to comply with our legal
        obligations, resolve disputes, and enforce our agreements.
      </p>

      <h1>Your Privacy Rights and Choices</h1>
      <p>
        You have certain rights and may make certain choices regarding our
        processing of your personal information:
      </p>
      <ul>
        <li>
          You may request access to, correction of, or erasure of your personal
          information by emailing to help@lanatomy.ai.
        </li>
        <li>
          You may request that we restrict processing of your personal
          information or object to our processing of your personal information
          by contacting our Data Protection Officer, in which case you will no
          longer be able to have an account with Lanatomy AI.
        </li>
        <li>
          If you reside in the EEA, you have the right to lodge a complaint with
          the supervisory authority of your member state. For a list of
          supervisory authorities, please see{" "}
          <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
            http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
          </a>
          .
        </li>
        <li>
          You are not obligated to provide any personal information, but if you
          do not provide your email address, we will not be able to communicate
          with you.
        </li>
        <li>
          Our marketing emails tell you how to "opt-out." If you opt out, we may
          still send you non-marketing emails. Non-marketing emails include
          emails about your accounts and our business dealings with you.
        </li>
        <li>
          You can typically remove and reject cookies from our Service with your
          browser settings. Many browsers are set to accept cookies until you
          change your settings. If you remove or reject our cookies, it could
          affect how our Service works for you.
        </li>
      </ul>

      <h1>Advertising and Remarketing</h1>
      <p>
        We may allow other companies, called third-party ad servers or ad
        networks, to serve advertisements within the Site or the App. These
        third-party ad servers or ad networks use technology to send, directly
        to your browser, the advertisements and links that appear on the Site.
        They automatically receive your IP Address when this happens. They may
        also use other technologies (such as cookies, JavaScript, or web
        beacons) to measure the effectiveness of their advertisements and to
        personalize their advertising content. Further, these advertisers may
        also serve ads to you on other websites (including ads for our products
        and services) based on information collected from you while using the
        Site.
      </p>
      <p>
        You should be aware that advertisers may target advertisements to users
        based on certain user characteristics. If you respond to this type of
        targeted advertisement, the advertiser or ad server may conclude that
        you fit the description of the audience they were trying to reach. Our
        Privacy Policy does not apply to, and we cannot control the activities
        of, such ad servers, ad networks, or their advertiser clients.
      </p>

      <h1>Links to Other Sites</h1>
      <p>
        Our Service may contain links to other websites that are not operated by
        us. If you click on a third-party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit. We have no control over, and assume no
        responsibility for the content, privacy policies, or practices of any
        third-party sites or services.
      </p>

      {/* page 4 */}
      <h1>Links to External Websites</h1>
      <p>
        The Site may contain links or references to external websites. These are
        provided for your convenience only, and we do not have control over the
        privacy or security practices and policies of third-party sites. Any
        personal information you provide on linked pages is provided directly to
        that third party and is subject to that third party's privacy policy.
        Please learn about the privacy and security practices and policies of
        external websites before providing them with personal information.
      </p>

      <h1>Children</h1>
      <p>
        Consistent with the federal Children's Online Privacy Protection Act of
        1998 (COPPA), we do not knowingly collect personally identifiable
        information from anyone under the age of thirteen (13) without parental
        consent. Any person who provides their personal information to us
        through the Site represents that they are 13 years of age or older. We
        ask for and store users’ date of birth to ensure they are 13 or older,
        but do not share that information unless the user chooses to share it on
        the user’s profile. If you believe that we have collected information
        from an individual under the age of 13, please contact us so that we may
        take appropriate steps to address the issue.
      </p>

      <h1>Security of Your Personal Information</h1>
      <p>
        We take steps to help protect personal information. No company can fully
        prevent security risks, however. Mistakes may happen. Bad actors may
        defeat even the best safeguards.
      </p>

      <h1>
        Transfer of Personal Information to the United States or Other Countries
      </h1>
      <p>
        Your personal information will be transferred to, and stored and
        processed in, Vietnam, and may in the future be transferred to, and
        stored and processed in, other countries. All transfers will either (a)
        be under the European Commission’s model contracts for the transfer of
        personal information to third countries, or (b) rely on the EU-US
        Privacy Shield framework.
      </p>

      <h1>Contact Information / Data Protection Officer</h1>
      <p>
        If you have questions, comments, or complaints about this Privacy Policy
        or our privacy practices, or would like to exercise any of your rights
        and choices under this Privacy Policy or applicable law, you may contact
        the following email address: help@lanatomy.ai
      </p>

      <h1>Changes to This Privacy Policy</h1>
      <p>
        We may occasionally update this Privacy Policy. When we do, we will also
        revise the "updated date" on the Privacy Policy. For changes to this
        Privacy Policy that may be materially less restrictive on our use or
        disclosure of personal information that you have provided to us, the new
        policy will not apply to information collected prior to the change
        unless we obtain your consent. We encourage you to periodically review
        this Privacy Policy to stay informed about how we are protecting the
        personal information we collect. Your continued use of the Service
        constitutes your going forward agreement to this Privacy Policy and any
        updates.
      </p>

      <p style={{ paddingBottom: 40 }}>
        This privacy policy was last updated on July 19, 2023.
      </p>
    </div>
  );
}
