import "./index.scss";

import { Images } from "../../utils";
import { APPSTORE, PLAYSTORE } from "../../utils/common";

import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  const InfoList = [
    // {
    //   img: Images.footer.email,
    //   value: "info@lanatomy.ai",
    //   icon: AiOutlineInstagram,
    // },
    // {
    //   img: Images.footer.pinteres,
    //   value: "@wordblob",
    //   icon: AiFillYoutube,
    // },
    // {
    //   img: Images.footer.instagram,
    //   value: "https://www.instagram.com/wordblob.app/",
    //   icon: AiOutlineInstagram,
    // },
    {
      img: Images.footer.facebook,
      value: "https://fb.com/wordblob",
      icon: FaFacebookF,
    },
  ];

  return (
    <div className="footer" id="contact">
      <Fade bottom>
        <div className="install-app">
          <span>Học từ bạn cần - Không lo quên mất</span>
          <div className="btn-wrap">
            <img
              alt=""
              src={Images.Homepage.GooglePlay}
              onClick={() => window.open(PLAYSTORE)}
            />
            <img
              alt=""
              src={Images.Homepage.AppleStore}
              onClick={() => window.open(APPSTORE)}
            />
          </div>
        </div>
      </Fade>
      <div className="left-info">
        <Fade bottom>
          <div className="logo-wrap">
            <img
              alt=""
              className="pointer logo"
              src={Images.Homepage.MobileIcon}
            />

            {InfoList.map((info) => {
              const Icon = info.icon;
              return (
                <Fade bottom key={info.value}>
                  <div className="icon">
                    <Icon
                      onClick={() => window.open(info.value)}
                      color="white"
                      size={20}
                    />
                  </div>
                </Fade>
              );
            })}
          </div>
        </Fade>
        <div className="info-wrap">
          {/* <div className="social">
            <Fade bottom>
              <span className="title">Thông tin liên hệ</span>
            </Fade>
            {InfoList.map((info) => {
              const Icon = info.icon;
              return (
                <Fade bottom key={info.value}>
                  <div className="social-row">
                    <div className="icon">
                      <Icon
                        onClick={() => window.open(info.value)}
                        color="white"
                        size={20}
                      />
                    </div>
                    <span>đường dẫn</span>
                  </div>
                </Fade>
              );
            })}
          </div>
          <div className="social">
            <Fade bottom>
              <span className="title">Thông tin</span>
            </Fade>
            <Fade bottom></Fade>
            <div className="social-row">
              <span>Affiliate Program</span>
            </div>
            <Fade bottom>
              <div className="social-row">
                <span>Tư vấn sử dụng App</span>
              </div>
            </Fade>
          </div> */}
          <div className="social">
            {/* <Fade bottom>
              <span className="title">Chính sách</span>
            </Fade> */}
            <Fade bottom>
              <div
                className="social-row"
                onClick={() => navigate("/privacy-policy")}
              >
                <span>Privacy Policy</span>
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="social-row"
                onClick={() => navigate("/terms-of-service")}
              >
                <span>Terms of Service</span>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div
        className="backToTop"
        onClick={() => {
          document.getElementById("top")?.scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        <span>Back to top</span>
        <img alt="" src={Images.footer.backToTop} />
      </div>
      <div className="copyright">
        <span>© {new Date().getFullYear()} Lanatomy AI</span>
      </div>
    </div>
  );
}
