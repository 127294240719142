import { Images } from "../../utils";
import "./index.scss";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";

export default function Introduction2() {
  return (
    <div className="introduction2">
      <div className="image-wrap">
        <Zoom delay>
          <div className="ball" />
        </Zoom>
        <Fade left>
          <img alt="" className="intro1" src={Images.Introduction2.intro1} />
        </Fade>
        <div className="intro2">
          <Fade bottom delay={300}>
            <span className="title">Học nhồi từ vựng theo lịch riêng</span>
          </Fade>
          <Fade left delay={300}>
            <img alt="" src={Images.Introduction2.intro2} />
          </Fade>
          <Fade bottom delay={300}>
            <img alt="" className="line" src={Images.Introduction2.line} />
          </Fade>
          <Fade bottom delay={300}>
            <span className="detail">
              Lanatomy AI sẽ thiết kế cho bạn{" "}
              <span className="line-through">lộ trình</span> nhồi từ vựng hằng
              ngày hiệu quả đúng kế hoạch.
            </span>
          </Fade>
        </div>
        <Zoom delay={700}>
          <img alt="" className="intro3" src={Images.Introduction2.intro3} />
        </Zoom>
      </div>
      <Fade bottom delay={300}>
        <div className="content-wrap">
          <span className="detail">
            Đặc biệt phù hợp khi bạn có mục tiêu ngắn hạn cụ thể cần sớm đạt
            được.
          </span>
          <span className="detail">
            Ví dụ, bạn đang luyện thi cấp tốc, bạn cần gây ấn tượng về mặt từ
            ngữ trong một cuộc phỏng vấn, hoặc đơn giản là bạn muốn nhồi cường
            độ cao các thẻ từ vựng trong kho riêng để đọc sách, xem phim không
            cần dò từ điển.
          </span>
        </div>
      </Fade>
      <Bounce>
        <img alt="" className="stampt" src={Images.Introduction2.stampt} />
      </Bounce>
    </div>
  );
}
