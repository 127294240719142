import Footer from "../Footer";
import Homepage from "../homepage";
import Introduction from "../Introduction";
import Introduction2 from "../Introduction2";
import Introduction3 from "../Introduction3";
import RandomReview from "../RandomReview";
import Story from "../Story";
import Subscription from "../Subscription";

export default function Main() {
  return (
    <div className="root-app" id="root-app">
      <Homepage />
      <RandomReview />
      <Story />
      <Introduction />
      <Introduction2 />
      <Introduction3 />
      <Subscription />
      <Footer />
    </div>
  );
}
