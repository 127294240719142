import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Images from "../../../utils/images";
import "./index.scss";

export default function AffiReason() {
  return (
    <div className="AffiReason">
      <div className="title">
        <Fade bottom>
          <img alt="" src={Images.affiReason.affiReason1} />
        </Fade>
        <Fade bottom>
          <span>
            Lý do khách hàng nên mua qua{" "}
            <span className="highlight">Affiliate</span>
          </span>
        </Fade>
      </div>

      <div className="reason-row">
        <Flip bottom>
          <div className="reason1">
            <div className="extra">
              <img alt="" src={Images.affiReason.affiReason7} />
              <span>Ưu đãi về giá</span>
            </div>
            <span className="title1">Được giảm ngay 10%</span>
            <span className="title2">trên giá niêm yết</span>
            <span className="detail">
              Khách hàng mặc định được nhận ưu đãi 10% giảm giá so với giá trên
              store khi mua qua Affiliate, kể cả khi đang có chương trình khuyến
              mãi sâu.
            </span>
            <img className="sale" alt="" src={Images.affiReason.affiReason2} />
          </div>
        </Flip>
        <Flip bottom>
          <div className="reason2">
            <div className="extra">
              <img alt="" src={Images.affiReason.affiReason8} />
              <span>Những chương trình ưu đãi tặng kèm</span>
            </div>
            <div className="main-row">
              <div className="main1">
                <div className="main1-row">
                  <img alt="" src={Images.affiReason.affiReason6} />
                  <span>Mua trên Store</span>
                </div>
                <img
                  className="logo"
                  alt=""
                  src={Images.affiReason.affiReason3}
                />
              </div>
              <div className="main2">
                <div className="main2-row">
                  <img alt="" src={Images.affiReason.affiReason5} />
                  <div className="detail-col">
                    <span>Mua thông qua</span>
                    <div className="detail-row">
                      <span>Affiliate</span>
                      <span className="detail">*Khi có chương trình</span>
                    </div>
                  </div>
                </div>
                <div className="main2-row2">
                  <img
                    className="logo"
                    alt=""
                    src={Images.affiReason.affiReason3}
                  />
                  <img
                    className="plus"
                    alt=""
                    src={Images.affiReason.affiReason11}
                  />
                  <img
                    className="giff"
                    alt=""
                    src={Images.affiReason.affiReason4}
                  />
                </div>
              </div>
            </div>
          </div>
        </Flip>
      </div>
      <Flip bottom>
        <div className="reason3">
          <img className="card" alt="" src={Images.affiReason.affiReason12} />
          <div className="col1">
            <div className="extra">
              <img alt="" src={Images.affiReason.affiReason9} />
              <span>Nhiều sự lựa chọn về gói đăng kí</span>
            </div>
            <span className="title1">Điều chỉnh gói dùng</span>
            <span className="title2">
              cá nhân hoá <img alt="" src={Images.affiReason.affiReason13} />
            </span>
            <span className="detail1">
              Khách hàng có thêm nhiều lựa chọn mua các gói dùng app có độ dài
              khác nhau tùy theo nhu cầu học tập khi mua qua Affiliate, so với
              khi mua qua store.
            </span>
            <span className="detail2">
              Affiliate có khả năng điều chỉnh và cung cấp các gói phí dùng app
              có độ dài phù hợp, kèm các khuyến mãi tương ứng.
            </span>
          </div>
          <div className="col2">
            <div className="main2-row">
              <img alt="" src={Images.affiReason.affiReason5} />
              <div className="detail-col">
                <span>Mua thông qua</span>
                <div className="detail-row">
                  <span>Affiliate</span>
                  <span className="detail">*Khi có chương trình</span>
                </div>
              </div>
            </div>
            <div className="package-ls">
              <span className="package-detail">Gói 3 tháng</span>
              <span className="package-detail">Gói 6 tháng</span>
              <span className="package-detail">Gói 9 tháng</span>
              <span className="package-detail package-detail-1">
                GÓI TRỌN ĐỜI
              </span>
              <span className="package-detail package-detail-2">
                Có cả lựa chọn 1 tháng, 1 năm
              </span>
            </div>
          </div>
          <div className="col3">
            <div className="empty" />
            <div className="main1-row">
              <img alt="" src={Images.affiReason.affiReason6} />
              <span>Mua trên Store</span>
            </div>
            <div className="package-ls">
              <span className="package-detail">Gói 1 tháng</span>
              <span className="package-detail anual">Gói 1 năm</span>
            </div>
          </div>
          <a href="mailto:info@lanatomy.ai?subject='Lanatomy AI Affiliate Program'">
            <div className="btn">
              <span>Liên hệ ngay</span>
              <img alt="" src={Images.Homepage.affiliate7} />
            </div>
          </a>
        </div>
      </Flip>
    </div>
  );
}
