const CommonUtilities = {
  sleep(sec) {
    return new Promise((resolve) => setTimeout(resolve, sec));
  },
};

export const PLAYSTORE =
  "https://play.google.com/store/apps/details?id=com.hanaslexis.wordblobapp";
export const APPSTORE = "https://apps.apple.com/us/app/wordblob/id1665297628";

export default CommonUtilities;
