import { Images } from "../../../utils";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import { APPSTORE, PLAYSTORE } from "../../../utils/common";

export default function HomeContent() {
  return (
    <>
      <div className="home-content-wrap">
        <div className="left-content">
          <Fade bottom>
            <span className="title">Giỏi từ vựng Anh nhờ vốn tiếng Việt</span>
          </Fade>
          <Fade bottom delay={300}>
            <span className="detail">
              Hơn 100.000 thẻ từ vựng cho mọi nhu cầu diễn đạt từ bình dân đến
              sâu sắc
            </span>
          </Fade>
          <div className="btn-wrap">
            <Bounce delay={300}>
              <img
                alt=""
                src={Images.Homepage.GooglePlay}
                onClick={() => window.open(PLAYSTORE)}
              />
            </Bounce>
            <Bounce delay={300}>
              <img
                alt=""
                src={Images.Homepage.AppleStore}
                onClick={() => window.open(APPSTORE)}
              />
            </Bounce>
          </div>
        </div>
        <div className="img-ls">
          <Fade right>
            <img alt="" src={Images.Homepage.DemoApp} />
          </Fade>
          <Zoom delay={400}>
            <div className="ball1" />
          </Zoom>
          <Zoom delay={400}>
            <div className="ball" />
          </Zoom>
          <Zoom delay={400}>
            <div className="ball2" />
          </Zoom>
        </div>
      </div>
    </>
  );
}
